import { Button, DisplayDate, Text } from '@crate.io/crate-gc-admin';
import { FormattedMessage, useIntl } from 'react-intl';
import CloudUITable from 'src/components/CloudUITable';
import StatusIndicator from 'src/components/StatusIndicator';
import { OPERATION_STATES, OperationState } from 'src/constants/defaults';
import { ClusterId, Integration, OrganizationId, ProjectId } from 'types';
import { INTEGRATION_SOURCES } from '../../ClusterImportIntegration/common/SourceStep/sources';
import { INTEGRATION_TYPES } from 'src/constants/integrations';
import { Popconfirm } from 'antd';
import {
  DeleteOutlined,
  InfoCircleOutlined,
  PauseOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import IntegrationsListDetailsModal from './IntegrationsListDetailsModal';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { clusterImportIntegration } from 'src/constants/paths';
import {
  INTEGRATION_ID_QUERY_PARAM,
  IngestionTypeOption,
} from '../../ClusterImportIntegration/constants';
import useMessage from 'src/hooks/useMessage';
import { apiPut } from 'src/api';
import { useGetClustersIdIntegrations } from 'src/swrTsHooks';

const DELETABLE_STATES: OperationState[] = [
  OPERATION_STATES.SUCCEEDED,
  OPERATION_STATES.FAILED,
  OPERATION_STATES.STOPPED,
];
const STOPPABLE_STATES: Record<IngestionTypeOption, OperationState[]> = {
  'cdc-only': [
    OPERATION_STATES.SENT,
    OPERATION_STATES.REGISTERED,
    OPERATION_STATES.IN_PROGRESS,
    OPERATION_STATES.RUNNING,
    OPERATION_STATES.FAILED,
  ],
  'import-and-cdc': [
    OPERATION_STATES.SENT,
    OPERATION_STATES.REGISTERED,
    OPERATION_STATES.IN_PROGRESS,
    OPERATION_STATES.RUNNING,
    OPERATION_STATES.FAILED,
  ],
  'import-only': [
    OPERATION_STATES.SENT,
    OPERATION_STATES.REGISTERED,
    OPERATION_STATES.IN_PROGRESS,
    OPERATION_STATES.RUNNING,
  ],
};
const RESUMABLE_STATES: OperationState[] = [OPERATION_STATES.STOPPED];

export type IntegrationsListProps = {
  integrations: Integration[];
  clusterId: ClusterId;
  organizationId: OrganizationId;
  projectId: ProjectId;
  onDelete: (integration: Integration) => void;
};

function IntegrationsList({
  integrations,
  clusterId,
  organizationId,
  projectId,
  onDelete,
}: IntegrationsListProps) {
  const navigate = useNavigate();
  const { showSuccessMessage, showErrorMessage, showLoadingMessage } = useMessage();
  const { formatNumber, formatMessage } = useIntl();
  const [integrationBeingViewed, setIntegrationBeingViewed] =
    useState<Integration | null>(null);

  // swr
  const { mutate: mutateIntegrations } = useGetClustersIdIntegrations(clusterId);

  const handleStop = async (integration: Integration) => {
    showLoadingMessage(
      formatMessage({
        id: 'cluster.clusterImportIntegration.stoppingIntegration',
      }),
    );

    const { success } = await apiPut(
      `/api/v2/clusters/${clusterId}/import-jobs/${integration.id}/stop/`,
      null,
      undefined,
      false,
    );

    if (success) {
      // show sccess message
      showSuccessMessage(
        formatMessage({
          id: 'cluster.clusterImportIntegration.integrationStopped',
        }),
      );
      await mutateIntegrations();
    } else {
      // show error message
      showErrorMessage(
        formatMessage({
          id: 'cluster.clusterImportIntegration.failedToStopIntegration',
        }),
      );
    }
  };

  const handleResume = async (integration: Integration) => {
    navigate(
      `${clusterImportIntegration.build({
        clusterId,
        organizationId,
        projectId,
      })}?${INTEGRATION_ID_QUERY_PARAM}=${integration.id}`,
    );
  };

  const getStatusIndicatorMessage = (integration: Integration) => {
    switch (integration.status) {
      case OPERATION_STATES.IN_PROGRESS:
        if (integration.progress.records > 0) {
          return (
            <FormattedMessage
              id="cluster.clusterImport.quickStatusIntegrationInProgressRecordsText"
              values={{
                records: formatNumber(integration.progress.records, {
                  notation: 'compact',
                }),
              }}
            />
          );
        }

        // fallback
        return (
          <FormattedMessage id="cluster.clusterImport.quickStatusIntegrationInProgressGenericText" />
        );
      case OPERATION_STATES.RUNNING:
        return (
          <FormattedMessage id="cluster.clusterImport.quickStatusIntegrationRunningText" />
        );
      case OPERATION_STATES.STOPPED:
        return (
          <FormattedMessage id="cluster.clusterImport.quickStatusStoppedIntegrationText" />
        );
      case OPERATION_STATES.REGISTERED:
      case OPERATION_STATES.SENT:
        return (
          <FormattedMessage id="cluster.clusterImport.quickStatusSettingUpIntegrationText" />
        );
      case OPERATION_STATES.SUCCEEDED:
        return (
          <FormattedMessage id="cluster.clusterImport.quickStatusIntegrationSucceededText" />
        );
      case OPERATION_STATES.FAILED:
        return (
          <FormattedMessage id="cluster.clusterImport.quickStatusIntegrationFailedText" />
        );
      default:
        return null;
    }
  };

  const columns = [
    {
      title: <FormattedMessage id="cluster.clusterImport.nameHeaderText" />,
      render: (integration: Integration) => (
        <span className="overflow-hidden text-ellipsis">{integration.name}</span>
      ),
      width: '25%',
    },
    {
      title: <FormattedMessage id="cluster.clusterImport.typeColumnHeaderText" />,
      responsive: ['lg'],
      render: (integration: Integration) => {
        const selectedSource = INTEGRATION_SOURCES.find(
          el => el.key === integration.type,
        );
        if (!selectedSource) {
          return 'Unknown';
        }

        return (
          <div className="flex items-center gap-2 text-black">
            {selectedSource.icon}
            <Text pale>
              <FormattedMessage id={selectedSource.title} />
            </Text>
          </div>
        );
      },
      width: '10%',
    },
    {
      render: (integration: Integration) => {
        switch (integration.type) {
          case INTEGRATION_TYPES.MONGODB:
            return `${integration.mongodb.database_name}.${integration.mongodb.collection_name}`;
        }
      },
      responsive: ['xl'],
      title: <FormattedMessage id="cluster.clusterImport.sourceHeaderText" />,
      width: '15%',
    },
    {
      dataIndex: ['destination', 'table'],
      responsive: ['xl'],
      title: <FormattedMessage id="cluster.clusterImport.destinationHeaderText" />,
      width: '15%',
    },
    {
      render: (integration: Integration) => (
        <DisplayDate isoDate={integration.dc.created} />
      ),
      responsive: ['lg'],
      title: <FormattedMessage id="cluster.clusterImport.dateColumnHeaderText" />,
      width: '20%',
    },
    {
      render: (integration: Integration) => (
        <StatusIndicator
          message={getStatusIndicatorMessage(integration)!}
          progress={Math.round(integration.progress.percent)}
          status={integration.status}
        />
      ),
      title: <FormattedMessage id="cluster.clusterImport.statusColumnHeaderText" />,
      width: '25%',
    },
    {
      render: (integration: Integration) => {
        return (
          <div className="flex justify-end gap-2">
            {STOPPABLE_STATES[integration.ingestion_type].includes(
              integration.status,
            ) && (
              <Popconfirm
                title={formatMessage(
                  {
                    id: 'cluster.clusterImport.stopIntegrationConfirmText',
                  },
                  {
                    name: integration.name,
                  },
                )}
                placement="bottomRight"
                okText={formatMessage({
                  id: 'common.yes',
                })}
                cancelText={formatMessage({
                  id: 'common.no',
                })}
                onConfirm={() => {
                  handleStop(integration);
                }}
              >
                <Button
                  type={Button.types.BUTTON}
                  kind={Button.kinds.TERTIARY}
                  id="stop-button"
                >
                  <PauseOutlined />
                </Button>
              </Popconfirm>
            )}
            {RESUMABLE_STATES.includes(integration.status) && (
              <Button
                type={Button.types.BUTTON}
                kind={Button.kinds.TERTIARY}
                id="resume-button"
                onClick={() => {
                  handleResume(integration);
                }}
              >
                <PlayCircleOutlined />
              </Button>
            )}
            <Button
              type={Button.types.BUTTON}
              kind={Button.kinds.TERTIARY}
              onClick={() => {
                setIntegrationBeingViewed(integration);
              }}
              id="show-details-button"
            >
              <InfoCircleOutlined />
            </Button>
            <Popconfirm
              title={formatMessage(
                {
                  id: 'cluster.clusterImport.deleteIntegrationConfirmText',
                },
                {
                  name: integration.name,
                },
              )}
              placement="bottomRight"
              okText={formatMessage({
                id: 'common.yes',
              })}
              cancelText={formatMessage({
                id: 'common.no',
              })}
              onConfirm={() => {
                onDelete(integration);
              }}
            >
              <Button
                disabled={!DELETABLE_STATES.includes(integration.status)}
                id="delete-integration-button"
                type={Button.types.BUTTON}
                kind={Button.kinds.TERTIARY}
              >
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </div>
        );
      },
      width: '15%',
    },
  ];

  return (
    <>
      <CloudUITable
        columns={columns}
        dataSource={integrations}
        pagination={integrations.length > 10}
        rowKey="id"
        showHeader
      />

      {integrationBeingViewed && (
        <IntegrationsListDetailsModal
          integration={integrationBeingViewed}
          show={integrationBeingViewed !== null}
          getStatusIndicatorMessage={getStatusIndicatorMessage}
          handleClose={() => setIntegrationBeingViewed(null)}
        />
      )}
    </>
  );
}

export default IntegrationsList;
