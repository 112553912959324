import { useState } from 'react';
import StepLayout from 'src/components/StepLayout';
import { ImportJobAzureBlob, ImportJobType } from 'types';
import { SourceStep, SourceStepSummary } from '../common/SourceStep';
import { useAnalytics } from 'src/hooks';
import useMessage from 'src/hooks/useMessage';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import {
  AZURE_SOURCE_FORM_DEFAULT_VALUES,
  CreateImportJobAzureBody,
} from './steps/form';
import { useGetClustersIdImportJobs } from 'src/swrHooks';
import { USER_TRACKING_EVENTS } from 'src/constants/segment';
import { apiPost } from 'src/api';
import { clusterImport } from 'src/constants/paths';
import ConnectionDetailsStep from './steps/ConnectionDetailsStep';
import { SinkDetailsStepForm } from '../constants';
import SinkDetailsStep from '../common/SinkDetailsStep';
import SourceDetailsStep from './steps/SourceDetailsStep';
import SourceDetailsStepSummary from './steps/SourceDetailsStepSummary';
import ConnectionDetailsStepSummary from './steps/ConnectionDetailsStepSummary';

export type AzureStorageSourceProps = {
  job?: ImportJobAzureBlob;
  sourceType: ImportJobType;
  setSourceType: (type: ImportJobType | null) => void;
};

function AzureStorageSource({
  job,
  sourceType,
  setSourceType,
}: AzureStorageSourceProps) {
  const { trackEvent } = useAnalytics();
  const { showSuccessMessage, showErrorMessage, showLoadingMessage } = useMessage();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { clusterId, organizationId, projectId } = useParams();

  const [stepIndex, setStepIndex] = useState(1);
  const [form, setForm] = useState<CreateImportJobAzureBody>(
    job || AZURE_SOURCE_FORM_DEFAULT_VALUES,
  );
  const [isCreatingImportJob, setIsCreatingImportJob] = useState(false);

  // swr
  const { data: importJobs, mutate: mutateImportJobs } = useGetClustersIdImportJobs(
    clusterId!,
  );

  const onNextStep = () => {
    setStepIndex(stepIndex => stepIndex + 1);
  };

  const onFinish = async (form: CreateImportJobAzureBody) => {
    setIsCreatingImportJob(true);
    showLoadingMessage(
      formatMessage({
        id: 'cluster.clusterImportFile.creatingImport',
      }),
    );

    trackEvent(USER_TRACKING_EVENTS.CLICKED_IMPORT_DATA);

    const { success, data } = await apiPost<ImportJobAzureBlob>(
      `/api/v2/clusters/${clusterId}/import-jobs/`,
      {
        azureblob: form.azureblob,
        compression: form.compression,
        destination: form.destination,
        format: form.format,
        type: form.type,
      },
    );

    setIsCreatingImportJob(false);
    if (success) {
      mutateImportJobs([data!, ...(importJobs || [])]);

      showSuccessMessage(
        formatMessage({
          id: 'cluster.clusterImportFile.importCreated',
        }),
      );

      // go back to job list
      navigate(
        clusterImport.build({
          clusterId,
          organizationId,
          projectId,
        }),
      );
    } else {
      // show error message
      showErrorMessage(
        formatMessage({
          id: 'cluster.clusterImportFile.errorCreatingImport',
        }),
      );
    }
  };

  return (
    <StepLayout
      stepIndex={stepIndex}
      setStepIndex={index => {
        if (index === 0) {
          // if we are going back to source step
          // then reset the source type
          setSourceType(null);
          setStepIndex(1);
        } else {
          setStepIndex(index);
        }
      }}
      steps={[
        {
          title: (
            <FormattedMessage id="cluster.clusterImportFile.sourceStep.title" />
          ),
          renderContent: () => (
            <SourceStep sourceType={sourceType} onChangeSourceType={setSourceType} />
          ),
          summaryContent: () => <SourceStepSummary sourceType={sourceType} />,
        },
        {
          title: (
            <FormattedMessage id="cluster.clusterImportFile.connectionDetailsStep.title" />
          ),
          renderContent: () => (
            <ConnectionDetailsStep
              defaultValues={form}
              onNext={(result: CreateImportJobAzureBody) => {
                setForm({
                  ...form,
                  ...result,
                  azureblob: {
                    ...form.azureblob,
                    ...result.azureblob,
                  },
                });
                onNextStep();
              }}
            />
          ),
          summaryContent: () => (
            <ConnectionDetailsStepSummary
              organizationId={organizationId!}
              values={form}
            />
          ),
        },
        {
          title: (
            <FormattedMessage id="cluster.clusterImportFile.sourceDetailsStep.title" />
          ),
          renderContent: () => (
            <SourceDetailsStep
              defaultValues={form}
              onNext={(result: CreateImportJobAzureBody) => {
                setForm({
                  ...form,
                  ...result,
                  azureblob: {
                    ...form.azureblob,
                    ...result.azureblob,
                  },
                });
                onNextStep();
              }}
            />
          ),
          summaryContent: () => <SourceDetailsStepSummary values={form} />,
        },
        {
          title: (
            <FormattedMessage id="cluster.clusterImportFile.sinkDetailsStep.title" />
          ),
          renderContent: () => (
            <SinkDetailsStep
              isSubmitting={isCreatingImportJob}
              defaultValues={form}
              onConfirm={(result: SinkDetailsStepForm) => {
                const finalForm = {
                  ...form,
                  ...result,
                };
                setForm(finalForm);
                onFinish(finalForm);
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default AzureStorageSource;
