import { useState } from 'react';
import StepLayout from 'src/components/StepLayout';
import { SourceStep, SourceStepSummary } from '../common/SourceStep';
import { File as CloudFile, ImportJob, ImportJobFile, ImportJobType } from 'types';
import SourceDetailsStep from './steps/SourceDetailsStep';
import SinkDetailsStep from '../common/SinkDetailsStep';
import { SinkDetailsStepForm } from '../constants';
import {
  CreateImportJobFileBody,
  FILE_SOURCE_FORM_DEFAULT_VALUES,
} from './steps/form';
import { useAnalytics } from 'src/hooks';
import { USER_TRACKING_EVENTS } from 'src/constants/segment';
import { apiPost } from 'src/api';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetClustersIdImportJobs } from 'src/swrHooks';
import { clusterImport } from 'src/constants/paths';
import { FormattedMessage, useIntl } from 'react-intl';
import useMessage from 'src/hooks/useMessage';
import SourceDetailsStepSummary from './steps/SourceDetailsStepSummary';

export type FileSourceProps = {
  job?: ImportJobFile;
  sourceType: ImportJobType;
  setSourceType: (type: ImportJobType | null) => void;
};

function FileSource({ job, sourceType, setSourceType }: FileSourceProps) {
  const { trackEvent } = useAnalytics();
  const { showSuccessMessage, showErrorMessage, showLoadingMessage } = useMessage();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { clusterId, organizationId, projectId } = useParams();

  const [stepIndex, setStepIndex] = useState(1);
  const [form, setForm] = useState<CreateImportJobFileBody>(
    job || FILE_SOURCE_FORM_DEFAULT_VALUES,
  );
  const [isCreatingImportJob, setIsCreatingImportJob] = useState(false);
  const [attachedFile, setAttachedFile] = useState<CloudFile | File | null>(
    job ? job.file : null,
  );

  // swr
  const { data: importJobs, mutate: mutateImportJobs } = useGetClustersIdImportJobs(
    clusterId!,
  );

  const onNextStep = () => {
    setStepIndex(stepIndex => stepIndex + 1);
  };

  const onFinish = async (form: CreateImportJobFileBody) => {
    setIsCreatingImportJob(true);
    showLoadingMessage(
      formatMessage({
        id: 'cluster.clusterImportFile.creatingImport',
      }),
    );

    // Create Job
    trackEvent(USER_TRACKING_EVENTS.CLICKED_IMPORT_DATA);

    const { success, data } = await apiPost<ImportJob>(
      `/api/v2/clusters/${clusterId}/import-jobs/`,
      {
        compression: form.compression,
        destination: form.destination,
        file: form.file,
        format: form.format,
        type: form.type,
      },
    );

    setIsCreatingImportJob(false);
    if (success) {
      mutateImportJobs([data!, ...(importJobs || [])]);

      showSuccessMessage(
        formatMessage({
          id: 'cluster.clusterImportFile.importCreated',
        }),
      );

      // go back to job list
      navigate(
        clusterImport.build({
          clusterId,
          organizationId,
          projectId,
        }),
      );
    } else {
      // show error message
      showErrorMessage(
        formatMessage({
          id: 'cluster.clusterImportFile.errorCreatingImport',
        }),
      );
    }
  };

  return (
    <StepLayout
      stepIndex={stepIndex}
      setStepIndex={index => {
        if (index === 0) {
          // if we are going back to source step
          // then reset the source type
          setSourceType(null);
          setStepIndex(1);
        } else {
          setStepIndex(index);
        }
      }}
      steps={[
        {
          title: (
            <FormattedMessage id="cluster.clusterImportFile.sourceStep.title" />
          ),
          renderContent: () => (
            <SourceStep sourceType={sourceType} onChangeSourceType={setSourceType} />
          ),
          summaryContent: () => <SourceStepSummary sourceType={sourceType} />,
        },
        {
          title: (
            <FormattedMessage id="cluster.clusterImportFile.sourceDetailsStep.title" />
          ),
          renderContent: () => (
            <SourceDetailsStep
              attachedFile={attachedFile}
              setAttachedFile={setAttachedFile}
              defaultValues={form}
              onNext={(result: CreateImportJobFileBody) => {
                setForm({
                  ...form,
                  ...result,
                });
                onNextStep();
              }}
            />
          ),
          summaryContent: () => (
            <SourceDetailsStepSummary values={form} attachedFile={attachedFile} />
          ),
        },
        {
          title: (
            <FormattedMessage id="cluster.clusterImportFile.sinkDetailsStep.title" />
          ),
          renderContent: () => (
            <SinkDetailsStep
              isSubmitting={isCreatingImportJob}
              defaultValues={form}
              onConfirm={(result: SinkDetailsStepForm) => {
                const finalForm = {
                  ...form,
                  ...result,
                };
                setForm(finalForm);
                onFinish(finalForm);
              }}
            />
          ),
        },
      ]}
    />
  );
}

export default FileSource;
