import { ValueOf } from '../../types';

export const DEFAULT_PLAN_TYPE = 3;

// slide-out drawer
// ---------------
export const DEFAULT_DRAWER_PLACEMENT = 'right';
export const DEFAULT_DRAWER_CLOSABLE = false;
export const DEFAULT_DRAWER_WIDTH = 598;

// URL Query params
// ----------------
export const QUERY_PARAM_KEY_SUBSCRIPTION_ID = 'subscriptionId';
export const QUERY_PARAM_KEY_REGION = 'region';
export const QUERY_PARAM_KEY_PRODUCT_NAME = 'productName';
export const QUERY_PARAM_KEY_PRODUCT_TIER = 'productTier';
export const QUERY_PARAM_KEY_PRODUCT_KIND = 'productKind';
export const QUERY_PARAM_KEY_SCALE_UNIT = 'productScaleUnit';
export const QUERY_PARAM_KEY_ACTIVE_TAB = 'tab';
export const QUERY_PARAM_KEY_AZURE_SUBSCRIPTION = 'azureSubscriptionId';

// local storage keys
// ------------------
export const SIDE_MENU_COLLAPSED_STORAGE_KEY = 'sidemenuIsCollapsed';
export const SAVED_ORGANIZATION_STORAGE_KEY = 'currentOrganization';
export const SAVED_PROJECT_STORAGE_KEY = 'currentProject';

// side menu
// ---------
export const SIDE_MENU_EXPANDED_WIDTH = 224;
export const SIDE_MENU_TOGGLE_DISABLED_THRESHOLD = 500;
export const SIDE_MENU_COLLAPSED_WIDTH = 80;

// interval between fetching fresh metrics for each period
// -------------------------------------------------------
export const EVERY_10_SECONDS_REFRESH_RATE = 10000; // 10 seconds
export const EVERY_30_SECONDS_REFRESH_RATE = 30000; // 30 seconds
export const EVERY_2_MINUTES_REFRESH_RATE = 1000 * 120; // 120 seconds

// backups sample periods
// ----------------------
export const CLUSTER_BACKUPS_LAST_2_DAYS = 'last2Days';
export const CLUSTER_BACKUPS_LAST_3_DAYS = 'last3Days';
export const CLUSTER_BACKUPS_LAST_WEEK = 'lastWeek';
export const CLUSTER_BACKUPS_LAST_2_WEEKS = 'last2Weeks';
export const CLUSTER_BACKUPS_ALL = 'all';

// Notification Durations
// Antd Notifications, not Antd Messages
// -------------------------------------
export const SHORT_MESSAGE_NOTIFICATION_DURATION = 7;
export const LONG_MESSAGE_NOTIFICATION_DURATION = 15;

// Clusters
// --------
export const OPERATION_STATES = {
  SENT: 'SENT',
  REGISTERED: 'REGISTERED',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  RUNNING: 'RUNNING',
  STOPPED: 'STOPPED',
} as const;
export type OperationState = ValueOf<typeof OPERATION_STATES>;

export const OPERATION_TYPES = {
  ALLOWED_CIDR_UPDATE: 'ALLOWED_CIDR_UPDATE',
  BACKUP_SCHEDULE_UPDATE: 'BACKUP_SCHEDULE_UPDATE',
  CHANGE_COMPUTE: 'CHANGE_COMPUTE',
  CREATE: 'CREATE',
  EXPAND_STORAGE: 'EXPAND_STORAGE',
  PASSWORD_UPDATE: 'PASSWORD_UPDATE',
  RESTORE_SNAPSHOT: 'RESTORE_SNAPSHOT',
  SCALE: 'SCALE',
  SUSPEND: 'SUSPEND',
  UPGRADE: 'UPGRADE',
} as const;
export type OperationType = ValueOf<typeof OPERATION_TYPES>;

export const HEALTH_STATES = {
  GREEN: 'GREEN',
  YELLOW: 'YELLOW',
  RED: 'RED',
  UNKNOWN: 'UNKNOWN',
  UNREACHABLE: 'UNREACHABLE',
  SUSPENDED: 'SUSPENDED',
} as const;
export type HealthState = ValueOf<typeof HEALTH_STATES>;

export const FILE_STATES = {
  PENDING: 'PENDING',
  UPLOADED: 'UPLOADED',
} as const;
export type FileState = ValueOf<typeof FILE_STATES>;

export const FILE_UPLOAD_FILE_SIZE_LIMIT_BYTES = 1200000000;

export const CLUSTER_OPERATIONS_REFRESH_RATE = 5000; // poll every 5 seconds

// deploy
// -------------
export const PAYMENT_PROVIDER_BUTTON_TEST_ID = {
  STRIPE: 'STRIPE_OPTION',
  STRIPE_BANK_TRANSFER: 'STRIPE_BANK_TRANSFER_OPTION',
  CLOUD: 'CLOUD_OPTION',
  AWS: 'AWS_OPTION',
  AZURE: 'AZURE_OPTION',
  GCP: 'GCP_OPTION',
  CONTRACT: 'CONTRACT_OPTION',
} as const;
export type PaymentProviderButtonTestId = ValueOf<
  typeof PAYMENT_PROVIDER_BUTTON_TEST_ID
>;

export const DEPLOY_MANAGEMENT_SETTINGS_FORM = 'deploy-management-settings-form';
export const DEPLOY_CHOOSE_PAYMENT_PROVIDER_FORM =
  'deploy-choose-payment-provider-form';
export const DEPLOY_ADD_PAYMENT_METHOD_FORM = 'deploy-add-payment-method-form';

// subscriptions
// -------------
export const AWS_SUBSCRIPTION_CONFIRMATION_REFRESH_RATE = 5000; // poll every 5 seconds

// organizations
// ------------
export const AUDIT_LOGS_PAGINATION_LIMIT = 100;
export const DEFAULT_ORGANIZATION_NAME = 'My Organization';
export const ORGANIZATION_ROLE_FQNS = {
  org_admin: 'Organization Admin',
  org_member: 'Organization Member',
  project_admin: 'Project Admin',
  project_member: 'Project Member',
} as const;
export type OrganizationRoleFqn = keyof typeof ORGANIZATION_ROLE_FQNS;

// credits
// -------
export const CREDITS_STATES = {
  ACTIVE: 'ACTIVE',
  EXPIRED: 'EXPIRED',
} as const;
export type CreditState = ValueOf<typeof CREDITS_STATES>;

// regions
// -------
export const REGION_REFRESH_RATE = 30000; // fetch new every 30 seconds

export const REGION_STATES = {
  UP: 'UP',
  DOWN: 'DOWN',
  UNKNOWN: 'UNKNOWN',
} as const;
export type RegionState = ValueOf<typeof REGION_STATES>;

// products
// --------
export const DEFAULT_REGION_NAME = '_default_';
export const EDGE_PRODUCT_NAME = 'edge';

export const BACKUP_LOCATIONS = {
  DEFAULT: 'defaultBackupLocation',
  CUSTOM: 'customBackupLocation',
};
// cookies
// -------
export const LOGIN_OPTIONS_COOKIE = 'login_opts';
export const EMAIL_CONFIRMATION_PENDING_COOKIE = 'emailConfirmationPending';

// subscriptions
// -------------
export const SUBSCRIPTION_PROVIDER = {
  AWS: 'aws',
  AZURE: 'azure',
  GCP: 'gcp',
  CLOUD: 'cloud',
  CONTRACT: 'contract',
  STRIPE: 'stripe',
  STRIPE_BANK_TRANSFER: 'stripe_bank_transfer',
} as const;
export type SubscriptionProvider = ValueOf<typeof SUBSCRIPTION_PROVIDER>;

export const SUBSCRIPTION_STATES = {
  ACTIVE: 'active',
  PENDING: 'pending',
  TERMINATING: 'terminating',
  SUSPENDED: 'suspended',
} as const;
export type SubscriptionState = ValueOf<typeof SUBSCRIPTION_STATES>;

export const BANK_CARD_BRANDS = {
  AMEX: 'amex',
  DISCOVER: 'discover',
  JCB: 'jcb',
  MASTERCARD: 'mastercard',
  UNIONPAY: 'unionpay',
  VISA: 'visa',
};
export type BankCardBrand = ValueOf<typeof BANK_CARD_BRANDS>;

export const BILLING_MODELS = {
  PAYG: 'payg',
  CONTRACT: 'contract',
  TRIAL: 'trial',
} as const;
export type BillingModel = ValueOf<typeof BILLING_MODELS>;

// PAYMENT METHODS
export const PAYMENT_METHODS_TYPE = {
  AWS: 'aws',
  AZURE: 'azure',
  GCP: 'gcp',
  CLOUD: 'cloud',
  CONTRACT: 'contract',
  STRIPE: 'stripe',
  STRIPE_BANK_TRANSFER: 'stripe_bank_transfer',
} as const;
export type PaymentMethodType = ValueOf<typeof PAYMENT_METHODS_TYPE>;

// identity providers
// ------------------
export const IDENTITY_PROVIDER = {
  AZURE_AD: 'azuread',
  COGNITO: 'cognito',
  GITHUB: 'github',
  GOOGLE: 'google',
} as const;
export type IdentityProvider = ValueOf<typeof IDENTITY_PROVIDER>;

// identity providers
// ------------------
export const USER_IDENTITY_STATES = {
  NEW: 'new',
  ACTIVE: 'active',
} as const;
export type UserIdentityState = ValueOf<typeof USER_IDENTITY_STATES>;

export const SECRET_TYPES = {
  AWS: 'AWS',
  AZURE: 'AZURE',
} as const;
export type SecretType = ValueOf<typeof SECRET_TYPES>;

// Stripe Publishable API key
// - meant meant solely to identify our account with Stripe and is not secret
// - can safely be published to github
export const STRIPE_DEVELOPMENT_PUBLISHABLE_KEY =
  'pk_test_51HrmpJJOpFszwfhB6uAZy7MpsaCskrJoSFoXR15Pa7Mxz6b6sOuplWNytJDVHDoP6zloZi7boMlGo0r6petW8Q0T00SCuEEsxu';

export const STRIPE_PRODUCTION_PUBLISHABLE_KEY =
  'pk_live_51HrmpJJOpFszwfhBjjFimUxUuzf3ev3iWqpN4Qx8dO96kbrm26UmjYgxdhsHW0zyZScaGP7jQZQY3Tp2WKLrBWm900Vpio5LeR';

// Options to format a price using react-intl --> formatNumber
export const PRICE_OPTIONS_2DP = {
  style: 'currency',
  currency: 'USD',
} as const;
export type PriceOption2DP = ValueOf<typeof PRICE_OPTIONS_2DP>;

export const PRICE_OPTIONS_3DP = {
  currency: 'USD',
  style: 'currency',
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
} as const;
export type PriceOption3DP = ValueOf<typeof PRICE_OPTIONS_3DP>;

export const SINGLE_SCREEN_DEPLOYMENT_ANALYTICS_ORIGIN = 'single screen deploy';

// ant design messages
export const TOAST_NOTIFICATION_DURATION = 8; // in seconds

// special-feature cluster names
export const FREE_TIER_CLUSTER_PRODUCT_NAME = 'crfree';
export const SHARED_RESOURCES_CLUSTER_PRODUCT_NAME = 'cr0';

export const TUTORIALS = {
  ADVANCED_TIMESERIES_METADATA: 'ADVANCED_TIMESERIES_METADATA',
  JSON_MARKETING_DATA: 'JSON_MARKETING_DATA',
  NETFLIX_FULLTEXT_SEARCH: 'NETFLIX_FULLTEXT_SEARCH',
  TIMESERIES_WEATHER: 'TIMESERIES_WEATHER',
} as const;
export type Tutorial = ValueOf<typeof TUTORIALS>;

export const CLUSTER_CHANNELS = {
  STABLE: 'stable',
  TESTING: 'testing',
};
export type ClusterChannel = ValueOf<typeof CLUSTER_CHANNELS>;
