import { Text } from '@crate.io/crate-gc-admin';
import { FIELD_NAMES } from '../../constants';
import { useIntl } from 'react-intl';
import { CreateImportJobAzureBody } from './form';
import { useGetOrganizationsIdSecrets } from 'src/swrHooks';
import { OrganizationId } from 'types';
import LoadingContainer from 'src/components/LoadingContainer';
import TestSWRIsFetching from 'src/components/TestSWRIsFetching';

export type ConnectionDetailsStepSummaryProps = {
  organizationId: OrganizationId;
  values: CreateImportJobAzureBody;
};

function ConnectionDetailsStepSummary({
  organizationId,
  values,
}: ConnectionDetailsStepSummaryProps) {
  const { formatMessage } = useIntl();
  const secretId = values[FIELD_NAMES.AZURE_NAMESPACE][FIELD_NAMES.AZURE_SECRET_ID];

  const { data: secrets } = useGetOrganizationsIdSecrets(organizationId);

  const renderValue = (label: string, value: string) => {
    return (
      <div className="flex gap-2">
        <Text className="font-bold">{label}:</Text>
        <Text>{value}</Text>
      </div>
    );
  };

  return (
    <>
      <LoadingContainer
        loading={!secrets}
        render={() => {
          const secretName = (secrets || []).find(
            secret => secret.id === secretId,
          )!.name;

          return (
            <div>
              {renderValue(
                formatMessage({
                  id: 'cluster.clusterImportFile.connectionDetailsStep.secretName',
                }),
                secretName,
              )}
            </div>
          );
        }}
      />
      <TestSWRIsFetching fetchStatusList={[!secrets]} />
    </>
  );
}

export default ConnectionDetailsStepSummary;
