import { ValueOf } from 'types';

export const FILE_COMPRESSION_OPTIONS = {
  GZIP: 'gzip',
} as const;

export const SUPPORTED_FILE_COMPRESSION_TYPES = {
  GZIP: 'application/gzip',
  XGZIP: 'application/x-gzip',
} as const;
export type SupportedFileCompressionType = ValueOf<
  typeof SUPPORTED_FILE_COMPRESSION_TYPES
>;

export const SUPPORTED_FILE_TYPES = {
  ...SUPPORTED_FILE_COMPRESSION_TYPES,
  CSV: 'text/csv',
  JSON: 'application/json',
  PARQUET: 'parquet', // no MIME type registered
} as const;
export type SupportedFileType = ValueOf<typeof SUPPORTED_FILE_TYPES>;

export const UNSUPPORTED_FILE_TYPES = {
  SQL: 'sql',
  doc: 'doc',
  docx: 'docx',
  xls: 'xls',
  xlsx: 'xlsx',
} as const;
export type UnsupportedFileFormat = ValueOf<typeof UNSUPPORTED_FILE_TYPES>;
