import { Form, Input, Select } from 'antd';
import { Button } from '@crate.io/crate-gc-admin';
import { FormattedMessage, useIntl } from 'react-intl';
import { CreateIntegrationMongoDBBody } from './forms';
import ConstrainStepWidth from 'src/components/StepLayout/ConstrainStepWidth';
import { FIELD_NAMES, TYPE_MAPPING_OPTIONS } from '../../constants';
import INPUT_SANITIZATION from '../../../../../constants/inputSanitization';

export type TableStepProps = {
  defaultValues: CreateIntegrationMongoDBBody;
  onNext: (result: CreateIntegrationMongoDBBody) => void;
};

function TableStep({ defaultValues, onNext }: TableStepProps) {
  const { formatMessage } = useIntl();
  const [form] = Form.useForm<CreateIntegrationMongoDBBody>();

  const onFormFinish = (values: CreateIntegrationMongoDBBody) => {
    onNext(values);
  };

  return (
    <ConstrainStepWidth>
      <Form<CreateIntegrationMongoDBBody>
        autoComplete="off"
        form={form}
        initialValues={{
          ...defaultValues,
        }}
        layout="vertical"
        name="mongodb-integration-table-form"
        id="mongodb-integration-table-form"
        aria-label="mongodb-integration-table-form"
        onFinish={onFormFinish}
        requiredMark="optional"
      >
        <Form.Item<CreateIntegrationMongoDBBody>
          colon={false}
          label={
            <FormattedMessage id="cluster.clusterImportIntegration.tableStep.tableLabel" />
          }
          name={[FIELD_NAMES.DESTINATION_NAMESPACE, FIELD_NAMES.TABLE_NAME]}
          rules={[INPUT_SANITIZATION.TABLE_NAME]}
        >
          <Input
            placeholder={formatMessage({
              id: 'cluster.clusterImportIntegration.tableStep.tableNamePlaceholder',
            })}
          />
        </Form.Item>

        <Form.Item<CreateIntegrationMongoDBBody>
          colon={false}
          label={
            <FormattedMessage id="cluster.clusterImportIntegration.tableStep.typeMapping" />
          }
          name={[
            FIELD_NAMES.DESTINATION_NAMESPACE,
            FIELD_NAMES.OBJECT_TYPE_STRATEGY,
          ]}
          rules={[
            {
              required: true,
              message: formatMessage({
                id: 'cluster.clusterImportIntegration.tableStep.typeMappingRequiredText',
              }),
            },
          ]}
        >
          <Select
            data-testid="type-mapping-select"
            options={TYPE_MAPPING_OPTIONS}
            placeholder={
              <FormattedMessage id="cluster.clusterImportIntegration.tableStep.selectTypeMapping" />
            }
          />
        </Form.Item>

        <div className="flex">
          <Button type={Button.types.SUBMIT}>
            <FormattedMessage id="common.next" />
          </Button>
        </div>
      </Form>
    </ConstrainStepWidth>
  );
}

export default TableStep;
