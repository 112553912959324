import { FeatureToggleName } from 'src/constants/featureToggleNames';
import { FeatureFlagStatus } from 'types';

const getFeatureFlag = (
  featureFlags: FeatureFlagStatus | undefined,
  key: FeatureToggleName | undefined,
) => {
  if (featureFlags) {
    return featureFlags.flags.some(flag => flag.is_active && flag.name === key);
  }

  return false;
};

export default getFeatureFlag;
