import { Button } from '@crate.io/crate-gc-admin';
import { Divider, Select, Space } from 'antd';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SecretType } from 'src/constants/defaults';
import AddSecretDrawer from 'src/features/organization/OrganizationSettings/AddSecretDrawer';
import { useGetOrganizationsIdSecrets } from 'src/swrHooks';
import { OrganizationId, Secret, SecretId } from 'types';

export type SecretSelectProps = {
  value?: SecretId;
  type: SecretType;
  organizationId: OrganizationId;
  onChange?: (secretId: SecretId) => void;
};

function SecretSelect({ value, type, organizationId, onChange }: SecretSelectProps) {
  const { formatMessage } = useIntl();
  const [addSecretDrawerVisible, setAddSecretDrawerVisible] = useState(false);

  const { data: secrets } = useGetOrganizationsIdSecrets(organizationId);

  const handleKeyDrawerClosed = (secret: Secret) => {
    setAddSecretDrawerVisible(false);
    if (onChange) {
      onChange(secret.id);
    }
  };

  const customDropDownRender = (menu: React.ReactElement) => {
    return (
      <>
        {menu}
        <Divider style={{ margin: '4px 0' }} />
        <Space
          style={{
            padding: '0 10px 4px',
          }}
        >
          <Button
            onClick={() => setAddSecretDrawerVisible(true)}
            kind={Button.kinds.TERTIARY}
            type="button"
          >
            <FormattedMessage id="organization.secrets.addSecretButton" />
          </Button>
        </Space>
      </>
    );
  };

  return (
    <>
      <Select
        options={(secrets || [])
          .filter(secret => secret.type === type)
          .map(secret => ({
            label: `${secret.name} (${secret.description})`,
            value: secret.id,
          }))}
        value={value === '' ? undefined : value}
        loading={!secrets}
        onChange={onChange}
        placeholder={formatMessage({
          id: 'cluster.clusterImportFile.connectionDetailsStep.secretPlaceholder',
        })}
        dropdownRender={customDropDownRender}
      />
      <AddSecretDrawer
        onClose={handleKeyDrawerClosed}
        organizationId={organizationId}
        isVisible={addSecretDrawerVisible}
        secretType={type}
      />
    </>
  );
}

export default SecretSelect;
