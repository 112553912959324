import { useMemo } from 'react';
import cx from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Link, NavLink } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { LeftCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import {
  HEALTH_STATES,
  SIDE_MENU_COLLAPSED_WIDTH,
  SIDE_MENU_EXPANDED_WIDTH,
} from '../../constants/defaults';
import userHelpNavigation from '../../constants/userHelpNavigation';
import {
  accountRoutes,
  CLUSTER_ROUTE_DISABLED_REASON,
  Route,
  RouteContext,
} from '../../constants/routes';
import MenuCollapseToggle from './MenuCollapseToggle';
import getFeatureFlag from '../../utils/data/features';
import {
  useGetClustersId,
  useGetFeaturesStatus,
  useGetUsersMe,
} from '../../swrHooks';
import { useThemedMenu } from '../../hooks';
import usePersistentStore from '../../state/persistent';
import { getUserIsOrganizationAdmin } from '../../utils/data/user';
import {
  getClusterDeployIsComplete,
  getClusterIsSuspendedOrSuspending,
} from '../../utils/data/cluster';
import { organizationClustersList, accountIndexPage } from '../../constants/paths';
import logo from '../../assets/cratedb_cloud_logo.svg';
import logoShape from '../../assets/logo_shape.svg';

export type SideMenuProps = {
  organizationId?: string;
};

function SideMenu({ organizationId }: SideMenuProps) {
  const { mode, currentURLPath, theme } = useThemedMenu();
  const clusterId = currentURLPath.includes('/clusters/')
    ? currentURLPath.split('/clusters/')[1].split('/')[0]
    : null;
  const { setSidemenuIsCollapsed, sidemenuIsCollapsed } = usePersistentStore();
  const { data: cluster } = useGetClustersId(clusterId);
  const { data: featureFlags } = useGetFeaturesStatus();
  const { data: user } = useGetUsersMe();

  const menuCollapseToggle = () => (
    <MenuCollapseToggle
      toggleSideMenu={() => setSidemenuIsCollapsed(!sidemenuIsCollapsed)}
      collapsed={sidemenuIsCollapsed}
    />
  );

  const userIsOrgAdmin = useMemo(
    () => getUserIsOrganizationAdmin(user, organizationId),
    [user, organizationId],
  );

  const isRouteDisabled = (route: Route) => {
    // currently, only cluster routes can be disabled
    if (!clusterId) {
      return false;
    }

    // everything is disabled until we have a cluster object to inspect
    if (!cluster) {
      return true;
    }

    // CLUSTER_DEPLOY_INCOMPLETE
    if (
      route.disabledWhen?.includes(
        CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_DEPLOY_INCOMPLETE,
      ) &&
      !getClusterDeployIsComplete(cluster)
    ) {
      return true;
    }

    // CLUSTER_IS_CRFREE
    if (
      route.disabledWhen?.includes(
        CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_IS_CRFREE,
      ) &&
      cluster.product_name === 'crfree'
    ) {
      return true;
    }

    // CLUSTER_SUSPENDED
    if (
      route.disabledWhen?.includes(
        CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_SUSPENDED,
      ) &&
      getClusterIsSuspendedOrSuspending(cluster)
    ) {
      return true;
    }

    // CLUSTER_UNHEALTHY
    if (
      route.disabledWhen?.includes(
        CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_UNHEALTHY,
      ) &&
      cluster?.health?.status !== HEALTH_STATES.GREEN
    ) {
      return true;
    }

    // no reason to disable this route
    return false;
  };

  const getMenuOptions = (context: RouteContext) => {
    const pathParams = {
      organizationId: organizationId || 'none',
      projectId: cluster?.project_id || 'none',
      clusterId: clusterId || 'none',
    };

    return accountRoutes
      .filter(route => route.navigationContext === context)
      .filter(route => !route.adminOnly || route.adminOnly === userIsOrgAdmin)
      .filter(route =>
        'featureFlag' in route
          ? getFeatureFlag(featureFlags, route.featureFlag)
          : true,
      )
      .filter(route =>
        'grandCentralRequired' in route ? cluster?.gc_available : true,
      )
      .filter(route =>
        route?.employeeOnly
          ? user?.email?.toLowerCase().endsWith('@crate.io')
          : true,
      )
      .map(route => {
        const path = route.path.build(pathParams);

        return {
          disabled: isRouteDisabled(route),
          icon: route.icon,
          key: path,
          label: <NavLink to={path}>{route.displayName}</NavLink>,
          selected: currentURLPath.startsWith(path),
        };
      });
  };

  const menuOptions = getMenuOptions(
    clusterId ? 'clusterLinks' : 'organizationLinks',
  );

  return (
    <Layout.Sider
      breakpoint="md"
      className={cx('fixed', 'h-screen', { collapsed: sidemenuIsCollapsed })}
      collapsed={sidemenuIsCollapsed}
      collapsedWidth={SIDE_MENU_COLLAPSED_WIDTH}
      theme={theme}
      width={SIDE_MENU_EXPANDED_WIDTH}
      data-testid={
        sidemenuIsCollapsed ? 'side-menu-collapsed' : 'side-menu-expanded'
      }
    >
      <div className="relative flex h-screen flex-col">
        {/* crate logo */}
        <div className="px-6 py-3" data-testid="crate-logo">
          <Link
            to={
              organizationId
                ? organizationClustersList.build({ organizationId })
                : accountIndexPage.path
            }
            className="w-full"
          >
            <img
              alt="CrateDB Cloud logo"
              className={cx('mx-auto', 'h-5', {
                'w-6': sidemenuIsCollapsed,
              })}
              src={sidemenuIsCollapsed ? logoShape : logo}
            />
          </Link>
        </div>

        <div className="h-full overflow-y-auto">
          {/* user has organization, show full nav */}
          {organizationId ? (
            <div className="flex h-full w-full flex-col">
              <div className="flex grow">
                <div
                  className="flex flex-1 flex-col overflow-y-auto"
                  data-testid="organization-navigation"
                >
                  {clusterId && (
                    <>
                      {sidemenuIsCollapsed && (
                        <Link
                          to={organizationClustersList.build({ organizationId })}
                          className="group-hover:text-white"
                        >
                          <div className="group mb-2 mt-1 bg-black px-6 py-4 text-center leading-tight text-white hover:bg-crate-blue">
                            <LeftCircleOutlined className="text-lg" />
                          </div>
                        </Link>
                      )}
                      {!sidemenuIsCollapsed && (
                        <div className="mb-2 mt-1 bg-black">
                          <Link
                            to={organizationClustersList.build({ organizationId })}
                          >
                            <div className="group flex items-center gap-2 bg-black px-6 py-2 text-[13px] leading-tight text-white opacity-80 hover:text-crate-blue hover:opacity-100">
                              <LeftCircleOutlined className="text-[12px]" />
                              <FormattedMessage id="sideMenu.backToClusters" />
                            </div>
                          </Link>
                          <div className="px-6 pb-2 pt-0.5 text-lg font-bold leading-tight text-white">
                            {cluster ? cluster.name : <LoadingOutlined />}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  <Menu
                    /*
                    // @ts-expect-error: overload */
                    mode={mode}
                    selectedKeys={menuOptions
                      .filter(option => option.selected)
                      .map(option => option.key)}
                    theme={theme}
                    items={menuOptions}
                  />
                </div>
              </div>

              <div className="w-full">
                {/*
                // @ts-expect-error: overload */}
                <Menu mode={mode} theme={theme} items={userHelpNavigation} />
                {menuCollapseToggle()}
              </div>
            </div>
          ) : (
            <div
              className="absolute bottom-0 w-full"
              data-testid="no-organization-navigation"
            >
              {/* user does not have organization, show basic links */}
              {/*
                // @ts-expect-error: overload */}
              <Menu mode={mode} theme={theme} items={userHelpNavigation} />
              {menuCollapseToggle()}
            </div>
          )}
        </div>
      </div>
    </Layout.Sider>
  );
}

export default SideMenu;
