import React from 'react';
import {
  ApartmentOutlined,
  AppstoreOutlined,
  AuditOutlined,
  BankOutlined,
  CodeOutlined,
  CalendarOutlined,
  CloudSyncOutlined,
  ClusterOutlined,
  ExportOutlined,
  FieldTimeOutlined,
  GlobalOutlined,
  ImportOutlined,
  PlaySquareOutlined,
  SettingOutlined,
  UserOutlined,
  ApiOutlined,
} from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import Onboarding from 'src/features/account/Onboarding';
import DeployCluster from 'src/features/deploy/DeployCluster';
import CreateOrganization from 'src/features/account/CreateOrganization';
import ClustersList from 'src/features/organization/ClustersList';
import OrganizationSettings from 'src/features/organization/OrganizationSettings';
import Billing from 'src/features/organization/Billing';
import Usage from 'src/features/organization/Usage';
import AuditLogs from 'src/features/organization/AuditLogs';
import Regions from 'src/features/organization/Regions';
import Account from 'src/features/account/AccountSettings';
import AccountRouter from 'src/components/AccountRouter';
import AzureUnauthorized from 'src/features/account/AzureUnauthorized';
import AcceptInvite from 'src/features/organization/AcceptInvite';
import MarketplaceSetup from 'src/features/account/MarketplaceSetup';

import ClusterAutomation from 'src/features/clusters/ClusterAutomation';
import ClusterBackups from 'src/features/clusters/ClusterBackups';
import ClusterExport from 'src/features/clusters/ClusterExport';
import ClusterImport from 'src/features/clusters/ClusterImport';
import ClusterImportFile from 'src/features/clusters/ClusterImportFile';
import ClusterLearn from 'src/features/clusters/ClusterLearn';
import ClusterManage from 'src/features/clusters/ClusterManage';
import ClusterOverview from 'src/features/clusters/ClusterOverview';
import ClusterScale from 'src/features/clusters/ClusterScale';
import ClusterSQL from 'src/features/clusters/ClusterSQL';
import ClusterIntegrations from 'src/features/clusters/ClusterIntegrations';
import ClusterImportIntegration from 'src/features/clusters/ClusterImportIntegration';

import {
  acceptInvite,
  accountIndexPage,
  accountSettings,
  azureUnauthorized,
  clusterAutomation,
  clusterBackup,
  clusterExport,
  clusterImport,
  clusterImportIntegration,
  clusterImportFile,
  clusterIntegrations,
  clusterManage,
  clusterOverview,
  clusterScale,
  clusterSQLConsole,
  clusterTutorials,
  createOrganization,
  deployOrganizationCluster,
  marketplaceSetup,
  organizationClustersList,
  organizationSettings,
  organizationBilling,
  organizationAuditLogs,
  organizationRegions,
  root,
  organizationUsage,
} from './paths';
import featureToggleNames, { FeatureToggleName } from './featureToggleNames';
import Path from 'path-parser';

export const navigationContexts = {
  ACCOUNT: 'accountLinks',
  CLUSTER: 'clusterLinks',
  ORGANIZATION: 'organizationLinks',
} as const;

export const CLUSTER_ROUTE_DISABLED_REASON = {
  CLUSTER_DEPLOY_INCOMPLETE: 'CLUSTER_DEPLOY_INCOMPLETE',
  CLUSTER_IS_CRFREE: 'CLUSTER_IS_CRFREE',
  CLUSTER_UNHEALTHY: 'CLUSTER_UNHEALTHY',
  CLUSTER_SUSPENDED: 'CLUSTER_SUSPENDED',
} as const;

export type RouteContext =
  | 'accountLinks'
  | 'clusterLinks'
  | 'organizationLinks'
  | null;
export type Route = {
  path: Path;
  component: () => React.JSX.Element;
  withSubroutes?: boolean;
  navigationContext?: RouteContext;
  disabledWhen?: string[];
  employeeOnly?: boolean;
  adminOnly?: boolean;
  icon?: React.ReactNode;
  displayName?: React.ReactNode;
  featureFlag?: FeatureToggleName;
  grandCentralRequired?: boolean;
  fullscreen?: boolean;
};
export const routes: Route[] = [
  {
    path: accountIndexPage,
    component: AccountRouter,
    withSubroutes: true,
  },
  {
    path: azureUnauthorized,
    component: AzureUnauthorized,
  },
  {
    path: acceptInvite,
    component: AcceptInvite,
  },
  {
    path: root,
    component: Onboarding,
    navigationContext: null,
  },
];

export const accountRoutes: Route[] = [
  // cluster routes
  {
    adminOnly: false,
    path: clusterOverview,
    component: ClusterOverview,
    icon: <AppstoreOutlined />,
    displayName: <FormattedMessage id="sideMenu.clusterOverview" />,
    navigationContext: navigationContexts.CLUSTER,
  },
  {
    adminOnly: true,
    path: clusterSQLConsole,
    component: ClusterSQL,
    icon: <CodeOutlined />,
    displayName: <FormattedMessage id="sideMenu.clusterConsole" />,
    navigationContext: navigationContexts.CLUSTER,
    featureFlag: featureToggleNames.FEATURE_GRAND_CENTRAL_ENABLED,
    grandCentralRequired: true,
    disabledWhen: [
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_DEPLOY_INCOMPLETE,
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_SUSPENDED,
    ],
    fullscreen: true,
  },
  {
    adminOnly: true,
    path: clusterAutomation,
    component: ClusterAutomation,
    icon: <CalendarOutlined />,
    displayName: <FormattedMessage id="sideMenu.clusterAutomation" />,
    navigationContext: navigationContexts.CLUSTER,
    featureFlag: featureToggleNames.FEATURE_GRAND_CENTRAL_ENABLED,
    grandCentralRequired: true,
    disabledWhen: [
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_DEPLOY_INCOMPLETE,
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_SUSPENDED,
    ],
    withSubroutes: true,
  },
  {
    adminOnly: true,
    path: clusterIntegrations,
    component: ClusterIntegrations,
    icon: <ApiOutlined />,
    displayName: <FormattedMessage id="sideMenu.clusterIntegrations" />,
    navigationContext: navigationContexts.CLUSTER,
    disabledWhen: [
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_DEPLOY_INCOMPLETE,
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_SUSPENDED,
    ],
  },
  {
    adminOnly: true,
    path: clusterImport,
    component: ClusterImport,
    icon: <ImportOutlined />,
    displayName: <FormattedMessage id="sideMenu.clusterImport" />,
    navigationContext: navigationContexts.CLUSTER,
    disabledWhen: [
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_DEPLOY_INCOMPLETE,
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_SUSPENDED,
    ],
    fullscreen: true,
  },
  {
    adminOnly: false,
    path: clusterImportFile,
    component: ClusterImportFile,
    fullscreen: true,
  },
  {
    adminOnly: false,
    path: clusterImportIntegration,
    component: ClusterImportIntegration,
    fullscreen: true,
  },
  {
    adminOnly: true,
    path: clusterExport,
    component: ClusterExport,
    icon: <ExportOutlined />,
    displayName: <FormattedMessage id="sideMenu.clusterExport" />,
    navigationContext: navigationContexts.CLUSTER,
    disabledWhen: [
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_DEPLOY_INCOMPLETE,
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_SUSPENDED,
    ],
  },
  {
    adminOnly: false,
    path: clusterBackup,
    component: ClusterBackups,
    icon: <CloudSyncOutlined />,
    displayName: <FormattedMessage id="sideMenu.clusterBackups" />,
    navigationContext: navigationContexts.CLUSTER,
    disabledWhen: [CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_DEPLOY_INCOMPLETE],
  },
  {
    adminOnly: true,
    path: clusterScale,
    component: ClusterScale,
    icon: <ClusterOutlined />,
    displayName: <FormattedMessage id="sideMenu.clusterScale" />,
    navigationContext: navigationContexts.CLUSTER,
    disabledWhen: [
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_DEPLOY_INCOMPLETE,
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_SUSPENDED,
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_UNHEALTHY,
    ],
  },
  {
    adminOnly: true,
    path: clusterManage,
    component: ClusterManage,
    icon: <SettingOutlined />,
    displayName: <FormattedMessage id="sideMenu.clusterManage" />,
    navigationContext: navigationContexts.CLUSTER,
  },
  {
    adminOnly: false,
    path: clusterTutorials,
    component: ClusterLearn,
    icon: <PlaySquareOutlined />,
    displayName: <FormattedMessage id="sideMenu.clusterTutorials" />,
    navigationContext: navigationContexts.CLUSTER,
    disabledWhen: [
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_DEPLOY_INCOMPLETE,
      CLUSTER_ROUTE_DISABLED_REASON.CLUSTER_SUSPENDED,
    ],
  },

  // organization routes
  {
    adminOnly: false,
    path: organizationClustersList,
    component: ClustersList,
    icon: <ApartmentOutlined />,
    displayName: <FormattedMessage id="sideMenu.organizationClustersListLink" />,
    navigationContext: navigationContexts.ORGANIZATION,
  },
  {
    adminOnly: false,
    path: organizationSettings,
    component: OrganizationSettings,
    icon: <SettingOutlined />,
    displayName: <FormattedMessage id="sideMenu.organizationSettingsLink" />,
    navigationContext: navigationContexts.ORGANIZATION,
  },
  {
    adminOnly: true,
    path: organizationBilling,
    component: Billing,
    icon: <BankOutlined />,
    displayName: <FormattedMessage id="sideMenu.organizationBillingLink" />,
    navigationContext: navigationContexts.ORGANIZATION,
  },
  {
    adminOnly: true,
    path: organizationUsage,
    component: Usage,
    icon: <FieldTimeOutlined />,
    displayName: <FormattedMessage id="sideMenu.organizationUsageLink" />,
    navigationContext: navigationContexts.ORGANIZATION,
    featureFlag: featureToggleNames.FEATURE_MONTHLY_CONSUMPTION,
  },
  {
    adminOnly: true,
    path: organizationAuditLogs,
    component: AuditLogs,
    icon: <AuditOutlined />,
    displayName: <FormattedMessage id="sideMenu.organizationAuditLogsLink" />,
    navigationContext: navigationContexts.ORGANIZATION,
  },
  {
    adminOnly: false,
    employeeOnly: true,
    path: organizationRegions,
    component: Regions,
    icon: <GlobalOutlined />,
    displayName: <FormattedMessage id="sideMenu.organizationRegionsLink" />,
    navigationContext: navigationContexts.ORGANIZATION,
  },

  // account routes
  {
    adminOnly: false,
    path: accountSettings,
    component: Account,
    icon: <UserOutlined />,
    displayName: <FormattedMessage id="sideMenu.accountSettingsLink" />,
    navigationContext: navigationContexts.ACCOUNT,
  },

  // misc routes, e.g. onboarding, org creation etc.

  // If no organization exists the user is redirected to createOrganization on this view
  // or if the SINGLE_SCREEN_DEPLOYMENT feature flag is enabled, redirect to deployCluster
  {
    adminOnly: false,
    path: accountIndexPage,
    component: Onboarding,
  },

  {
    adminOnly: false,
    path: createOrganization,
    component: CreateOrganization,
  },

  // having 3 separate routes here helps us populate the breadcrumbs
  // in the deploy overview UI as the breadcrumb data is taken from the URL params

  // deploying when there is only an organization
  {
    adminOnly: false,
    path: deployOrganizationCluster,
    component: DeployCluster,
  },

  {
    adminOnly: false,
    path: marketplaceSetup,
    component: MarketplaceSetup,
  },
] as const;
