import { Text } from '@crate.io/crate-gc-admin';
import { FIELD_NAMES, TYPE_MAPPING_OPTIONS } from '../../constants';
import { useIntl } from 'react-intl';
import { CreateIntegrationMongoDBBody } from './forms';

export type TableStepSummaryProps = {
  values: CreateIntegrationMongoDBBody;
};

function TableStepSummary({ values }: TableStepSummaryProps) {
  const { formatMessage } = useIntl();
  const tableName =
    values[FIELD_NAMES.DESTINATION_NAMESPACE][FIELD_NAMES.TABLE_NAME];
  const typeMapping = TYPE_MAPPING_OPTIONS.find(
    option =>
      option.value ===
      values[FIELD_NAMES.DESTINATION_NAMESPACE][FIELD_NAMES.OBJECT_TYPE_STRATEGY],
  )!.label;

  const renderValue = (label: string, value: string) => {
    return (
      <div className="flex gap-2">
        <Text className="font-bold">{label}:</Text>
        <Text>{value}</Text>
      </div>
    );
  };

  return (
    <div>
      {renderValue(
        formatMessage({
          id: 'cluster.clusterImportIntegration.tableStep.tableLabel',
        }),
        tableName,
      )}
      {renderValue(
        formatMessage({
          id: 'cluster.clusterImportIntegration.tableStep.typeMapping',
        }),
        typeMapping,
      )}
    </div>
  );
}

export default TableStepSummary;
