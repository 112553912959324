export const MONGODB_X509_CONNECTION_STRING_REGEX =
  /^mongodb\+srv:\/\/([a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*)(\/)?(\?authSource=%24external&authMechanism=MONGODB-X509(&[a-zA-Z0-9]+=[a-zA-Z0-9]+)*)?$/;

export const MONGODB_SCRAM_CONNECTION_STRING_REGEX =
  /^mongodb\+srv:\/\/([a-zA-Z0-9_-]+)\.([a-zA-Z0-9_-]+)\.mongodb\.net\/\?retryWrites=true&w=majority(&appName=[a-zA-Z0-9_-]+)?$/;

export const MONGODB_COMPLETE_SCRAM_CONNECTION_STRING_REGEX =
  /mongodb\+srv:\/\/(?:(?<username>[^:]+):(?<password>[^@]+)@)?/;

export const MONGODB_USERNAME_PLACEHOLDER = '<db_username>';
export const MONGODB_PASSWORD_PLACEHOLDER = '<db_password>';
