import { AUTH_TYPES } from './authTypes';
import { INGESTION_TYPES } from './ingestionTypes';
import { TYPE_MAPPINGS } from './typeMappings';

export const TYPE_MAPPING_OPTIONS = [
  {
    label: 'Dynamic object',
    value: TYPE_MAPPINGS.DYNAMIC_OBJECT,
  },
  {
    label: 'Ignored object',
    value: TYPE_MAPPINGS.IGNORED_OBJECT,
  },
];
export type TypeMappingOption = (typeof TYPE_MAPPING_OPTIONS)[number]['value'];

export const INGESTION_TYPE_OPTIONS = [
  {
    label: 'Full load and CDC',
    value: INGESTION_TYPES.IMPORT_AND_CDC,
  },
  {
    label: 'Full load only',
    value: INGESTION_TYPES.IMPORT_ONLY,
  },
  {
    label: 'CDC only',
    value: INGESTION_TYPES.CDC_ONLY,
  },
];
export type IngestionTypeOption = (typeof INGESTION_TYPE_OPTIONS)[number]['value'];

export const AUTH_TYPE_OPTIONS = [
  {
    label: 'SCRAM',
    value: AUTH_TYPES.SCRAM,
  },
  {
    label: 'X.509',
    value: AUTH_TYPES.X509,
  },
];
export type AuthTypeOption = (typeof AUTH_TYPE_OPTIONS)[number]['value'];
