import { clusterImport } from 'src/constants/paths';
import {
  INTEGRATION_ID_QUERY_PARAM,
  INTEGRATION_SOURCE_QUERY_PARAM,
} from './constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IntegrationMongoDB, IntegrationType } from 'types';
import { useState } from 'react';
import ConstrainWidth from 'src/components/ConstrainWidth';
import SectionContainer from 'src/components/SectionContainer';
import LoadingContainer from 'src/components/LoadingContainer';
import { Button, Heading } from '@crate.io/crate-gc-admin';
import { FormattedMessage } from 'react-intl';
import StepLayout from 'src/components/StepLayout';
import MongoDBSource from './MongoDBSource';
import { SourceStep } from './common/SourceStep';
import { useGetClustersIdIntegration } from 'src/swrHooks';
import { INTEGRATION_TYPES } from 'src/constants/integrations';
import Tag from 'src/components/Tag';
import featureToggleNames from 'src/constants/featureToggleNames';
import FeatureToggle from 'src/components/FeatureToggle';

function ClusterImportIntegration() {
  const navigate = useNavigate();
  const location = useLocation();
  const resumeIntegrationId = new URLSearchParams(location.search).get(
    INTEGRATION_ID_QUERY_PARAM,
  );
  const initialSource = new URLSearchParams(location.search).get(
    INTEGRATION_SOURCE_QUERY_PARAM,
  );
  const { clusterId, organizationId, projectId } = useParams();
  const [sourceType, setSourceType] = useState<IntegrationType | null>(
    initialSource ? (initialSource as IntegrationType) : null,
  );

  // swr
  const {
    data: resumeIntegration,
    isLoading: isLoadingResumeIntegration,
    mutate: mutateIntegration,
  } = useGetClustersIdIntegration(clusterId, resumeIntegrationId);

  const renderSourceForm = () => {
    switch (sourceType) {
      case null:
        return (
          <StepLayout
            stepIndex={0}
            setStepIndex={() => {}}
            steps={[
              {
                title: (
                  <FormattedMessage id="cluster.clusterImportIntegration.sourceStep.title" />
                ),
                renderContent: () => (
                  <SourceStep
                    sourceType={sourceType}
                    onChangeSourceType={type => {
                      setSourceType(type);
                    }}
                  />
                ),
              },
            ]}
          />
        );
      case INTEGRATION_TYPES.MONGODB:
        return (
          <MongoDBSource
            sourceType={sourceType}
            setSourceType={setSourceType}
            integration={resumeIntegration as IntegrationMongoDB | undefined}
            onUpdate={() => {
              mutateIntegration();
            }}
          />
        );
    }
  };

  return (
    <ConstrainWidth>
      <SectionContainer className="absolute h-full w-full overflow-x-auto">
        <LoadingContainer
          loading={isLoadingResumeIntegration}
          render={() => {
            if (resumeIntegration) {
              setSourceType(resumeIntegration.type);
            }
            return (
              <FeatureToggle
                featureName={featureToggleNames.FEATURE_INTEGRATIONS_ENABLED}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center gap-2">
                    <Heading level={Heading.levels.h2}>
                      <FormattedMessage
                        id={
                          resumeIntegrationId
                            ? 'cluster.clusterImportIntegration.resumeIntegrationTitle'
                            : 'cluster.clusterImportIntegration.title'
                        }
                      />
                    </Heading>
                    <Tag label="Beta" />
                  </div>

                  <Button
                    onClick={() => {
                      navigate(
                        clusterImport.build({
                          clusterId,
                          organizationId,
                          projectId,
                        }),
                      );
                    }}
                  >
                    <FormattedMessage id="common.cancel" />
                  </Button>
                </div>
                <div className="mt-2">{renderSourceForm()}</div>
              </FeatureToggle>
            );
          }}
        />
      </SectionContainer>
    </ConstrainWidth>
  );
}

export default ClusterImportIntegration;
