import { environment } from './environment';
import getIntercomPlan from '../utils/getIntercomPlan';
import { Organization, User, ValueOf } from '../../types';

export const getSegmentProps = (user: User, organization: Organization) => ({
  user_hash: user.hmac,
  user_id: user.uid,
  name: user.name,
  email: user.email,
  avatar: {
    type: 'avatar',
    image_url: user.picture,
  },
  tags: ['CLOUD_UI'],

  // custom attributes
  uid: user.uid,
  username: user.username,
  full_name: user.name,
  organization_id: organization.id,
  application: 'CLOUD_UI',
  companies: [
    {
      company_id: organization.id,
      name: organization.name,
      plan: getIntercomPlan(organization.plan_type),
    },
  ],
  environment,
});

// tracking events
export const USER_TRACKING_EVENTS = {
  CLICKED_ACCESS_ADMIN_UI: 'Clicked Access Admin-UI',
  CLICKED_BEGINNER_TUTORIAL: 'Clicked on beginner tutorial',
  CLICKED_CLUSTER_CHANGE_COMPUTE: 'Cluster Product Change triggered',
  CLICKED_CLUSTER_STORAGE_RESIZE: 'Cluster Storage Resize triggered',
  CLICKED_CLUSTER_OVERVIEW_FEATURE_PROMO:
    'Clicked feature promo link on cluster overview',
  CLICKED_CREATE_EDGE_REGION: 'Clicked Create Edge Region',
  CLICKED_CREATE_INTEGRATION: 'Clicked Create Integration',
  CLICKED_CREATE_TABLE_STATEMENT: 'Clicked Create Table statement',
  CLICKED_DASHBOARD_DEPLOY_CLUSTER: 'Clicked Dashboard deploy cluster',
  CLICKED_DELETE_EDGE_REGION: 'Clicked Delete Edge Region',
  CLICKED_DEPLOY: 'Clicked Deploy',
  CLICKED_EXPORT_DATA: 'Clicked Export Data',
  CLICKED_IMPORT_DATA: 'Clicked Import Data',
  CLICKED_JOBSCHEDULER_CREATE: 'Clicked Job Scheduler Create',
  CLICKED_JOBSCHEDULER_EDIT: 'Clicked Job Scheduler Edit',
  CLICKED_JOBSCHEDULER_REMOVE: 'Clicked Job Scheduler Remove',
  CLICKED_LEARN_IMPORT_DEMO_DATA: 'Clicked Learn how to import demo data',
  CLICKED_RESUME_INTEGRATION: 'Clicked Resume Integration',
  CLICKED_RESUME_TRIGGERED: 'Cluster Resume triggered',
  CLICKED_SCALE_CLUSTER: 'Scale Cluster triggered',
  CLICKED_SQL_CONSOLE_HISTORY: 'SQL Console History',
  CLICKED_SQL_CONSOLE_QUERY: 'SQL Console Query',
  CLICKED_SUSPEND_TRIGGERED: 'Cluster Suspend triggered',
  CLICKED_TABLEPOLICIES_CREATE: 'Clicked Table Policies Create',
  CLICKED_TABLEPOLICIES_EDIT: 'Clicked Table Policies Edit',
  CLICKED_TABLEPOLICIES_REMOVE: 'Clicked Table Policies Remove',
  CLICKED_UPGRADE_TRIGGERED: 'Cluster Upgrade triggered',
  CREATED_ORGANIZATION: 'Created Organization',
  DELETED_ORGANIZATION: 'Deleted Organization',
  DEPLOY_NEW_CLUSTER: 'Deploy new Cluster',
  NEW_CLOUD_SUBSCRIPTION: 'New Subscription via Cloud',
  RUN_QUERY_ADMIN_UI: 'Run query in Admin UI',
  TUTORIAL_IMPORT: 'Tutorial Import',
  USER_ADD_BANK_CARD: 'User adds Payment Method',
  USER_LOGGED_IN: 'User Logged In',
  USER_REQUEST_ENABLE_BANK_TRANSFER: 'User has requested to enable Bank Transfer',

  // legacy events
  COPY_FROM_STATEMENT_CLICKED: 'Copy from statement clicked',
  CLUSTER_DEPLOYED: 'Cluster deployed',
  CLICKED_LEARN_HOW_TO_CONNECT: 'Learn how to connect',
  CREATED_PROJECT: 'Created Project',
  DELETED_PROJECT: 'Deleted Project',
  RESTORE_BACKUP_CLICKED: 'Restore backup clicked',
} as const;
export type UserTrackingEvent = ValueOf<typeof USER_TRACKING_EVENTS>;
