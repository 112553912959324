import { ValueOf } from 'types';

export const SUPPORTED_FILE_COMPRESSION_FORMATS = {
  GZIP: 'gzip',
  GZ: 'gz',
} as const;
export type SupportedFileCompressionFormat = ValueOf<
  typeof SUPPORTED_FILE_COMPRESSION_FORMATS
>;

export const SUPPORTED_FILE_FORMATS = {
  ...SUPPORTED_FILE_COMPRESSION_FORMATS,
  CSV: 'csv',
  JSON: 'json',
  PARQUET: 'parquet',
} as const;
export type SupportedFileFormat = ValueOf<typeof SUPPORTED_FILE_FORMATS>;
