import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from 'antd';
import { SubmitButtonGroup, Text } from '@crate.io/crate-gc-admin';
import { useGetClustersId } from '../../swrHooks';
import { useAnalytics } from '../../hooks';
import { apiPut } from '../../api';
import { USER_TRACKING_EVENTS } from '../../constants/segment';
import useMessage from '../../hooks/useMessage';
import { Cluster } from 'types';

export type ConfirmSuspendResumeProps = {
  cluster: Cluster;
  suspend?: boolean;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  onCallback?: () => void;
};

function ConfirmSuspendResume({
  cluster,
  suspend = false,
  isVisible,
  setIsVisible,
  onCallback,
}: ConfirmSuspendResumeProps) {
  const { showSuccessMessage } = useMessage();
  const { formatMessage } = useIntl();
  const { mutate: mutateCluster } = useGetClustersId(cluster?.id);

  const { trackEvent } = useAnalytics();

  const closeModalHandler = () => {
    setIsVisible(false);
  };

  const suspendResumeClusterHandler = async () => {
    const { success, data: updatedCluster } = await apiPut(
      `/api/v2/clusters/${cluster.id}/suspend/`,
      {
        suspended: suspend,
      },
    );

    if (success) {
      if (onCallback) {
        onCallback();
      }
      mutateCluster(updatedCluster);
      showSuccessMessage(
        formatMessage({
          id: suspend
            ? 'cluster.suspendCluster.suspendClusterProgressText'
            : 'cluster.suspendCluster.resumeClusterProgressText',
        }),
      );
      trackEvent(
        suspend
          ? USER_TRACKING_EVENTS.CLICKED_SUSPEND_TRIGGERED
          : USER_TRACKING_EVENTS.CLICKED_RESUME_TRIGGERED,
      );
    }

    closeModalHandler();
  };

  return (
    <Modal
      closable={false}
      onCancel={closeModalHandler}
      open={isVisible}
      footer={
        <SubmitButtonGroup
          confirmLabel={
            <FormattedMessage
              id={
                suspend
                  ? 'cluster.suspendCluster.suspendClusterModalButton'
                  : 'cluster.suspendCluster.resumeClusterModalButton'
              }
            />
          }
          onCancel={closeModalHandler}
          onSubmit={suspendResumeClusterHandler}
        />
      }
      title={
        <FormattedMessage
          id={
            suspend
              ? 'cluster.suspendCluster.suspendClusterModalTitle'
              : 'cluster.suspendCluster.resumeClusterModalTitle'
          }
        />
      }
    >
      <Text>
        <FormattedMessage
          id={
            suspend
              ? 'cluster.suspendCluster.suspendClusterModalText'
              : 'cluster.suspendCluster.resumeClusterModalText'
          }
        />
      </Text>
    </Modal>
  );
}

export default ConfirmSuspendResume;
